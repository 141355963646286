import { Component, Input } from '@angular/core';
import { Const } from '@const/Const';
import { BaseFormDialog1 } from '@dialogs/base-form-dlg1';
import { DateUtil } from '@services/date-utils';
import { Log } from '@services/log';
import { MasterData } from '@services/master.data';
import { startOfDay, endOfDay } from 'date-fns';
import { Utils } from '@services/utils';
import { countryListAlpha2 } from '@app/admin/components/forms/form-address/country-code';
@Component({
  selector: 'filter-modal',
  templateUrl: './index.html',
  styleUrls: ['./index.scss']
})
export class FilterModal extends BaseFormDialog1 {

  constructor() {
    super();
   }
   protected formGroupDeclaration: FormGroupDeclaration = {
      status: { label: "Status", placeHolder: "Select", notAcceptEmpty: true },
      clientId: { label: 'Customer', notAcceptEmpty: true, placeHolder: 'Select' },
      shipmentType: { label: 'Shipment Type', placeHolder: 'Select', notAcceptEmpty: true },
      carrierId: { label: 'Carrier', notAcceptEmpty: true, placeHolder: 'Select' },
      driverId: { label: '', notAcceptEmpty: true },
      pickupLocation: { label: 'Pickup Location', placeHolder: 'Select', notAcceptEmpty: true },
      dropoffLocation: { label: 'Delivery Location', placeHolder: 'Select', notAcceptEmpty: true },
      pickupCountry: {label: '', initialValue: 'US'},
      dropoffCountry: {label: '', initialValue: 'US'},
      // equipment: { label: 'Equipment', placeHolder: 'Select', notAcceptEmpty: true },
      vehicleType: { label: 'Equipment', placeHolder: 'Select', notAcceptEmpty: true },
      fromDate: { label: 'From date', type: 'date', notAcceptEmpty: true, getValue: DateUtil.getDateFrom },
      toDate: { label: 'To date', type: 'date', notAcceptEmpty: true, getValue: DateUtil.getDateTo },
      fromTimezone: { label: 'Time zone', placeHolder: 'Time zone', notAcceptEmpty: true},
      toTimezone: { label: 'Time zone', placeHolder: 'Time zone', notAcceptEmpty: true},
      picId: { label: 'Dispatcher', placeHolder: 'Select', notAcceptEmpty: true },
      carrierSaleRepId: { label: 'Carrier Sales Rep', placeHolder: 'Select', notAcceptEmpty: true },
      clientSaleRepId: { label: 'Client Seller', placeHolder: 'Select', notAcceptEmpty: true },
      clientServiceUserId: { label: 'Client Account Owner', placeHolder: 'Select', notAcceptEmpty: true },
      clientServiceRepUserId: { label: 'Client Service Rep', placeHolder: 'Select', notAcceptEmpty: true },
      archivedTrackingTask: { label: 'Task queue', type: 'boolean', placeHolder: 'Select', notAcceptEmpty: true},
      clientFilterType: { label: 'Client Filter Type', placeHolder: 'Select', notAcceptEmpty: true, initialValue: 'include' },
      carrierFilterType: { label: 'Carrier Filter Type', placeHolder: 'Select', notAcceptEmpty: true, initialValue: 'include' },
   }
   get formInputKeys(): Array<string> {
    return this._formInputKeys;
  }
  @Input() dataForm: any;
  @Input() urlMyLoad: any;
  @Input() page: any;
  @Input() search: any;
  @Input() lastUpdated: any;
  @Input() lastUpdatedTime: any;
  @Input() requestId: any;
  public listTimezones = DateUtil.listUsTimezones;

  ngOnInit(): void {
    super.ngOnInit();
    this.fetchListCarriers();
    this.fetchListDispatchers();
    this.fetchListCarrierSales();
    this.fetchListClientSales();
    this.mappingLocationFields(this.dataForm);
    if (this.dataForm?.carrierId && Utils.isString(this.dataForm?.carrierId)) {
      this.shouldSelectMultipleCarrier = false;
    }
    if (this.dataForm?.fromDate && this.dataForm.fromTimezone) {
      let timezone = DateUtil.mapTimezoneUS(this.dataForm.fromTimezone);
      const date = DateUtil.convertLocalTime2(this.dataForm.fromDate, timezone).toISOString();
      this.dataForm.fromDate = new Date(date);
    }
    if (this.dataForm?.toDate && this.dataForm.toTimezone) {
      let timezone = DateUtil.mapTimezoneUS(this.dataForm.toTimezone);
      const date = DateUtil.convertLocalTime2(this.dataForm.toDate, timezone).toISOString();
      this.dataForm.toDate = date;
    }
    this.setFormValues(this.dataForm);
    this.getListWarehouse();
  }

  public listCarriers = [];
  public listCarrierSales = [];
  public listClientSales = [];
  public listDispatchers = [];
  public isFetchingCarrier = false;
  public isFetchingCarrierSaleRep = false;
  listDispatcherForDropdown: any[] = [];
  dispatchersHaveJobs = [];
  public countriesStates = MasterData.getCountriesStates_forSelectGroup();
  public listShipmentTypes = Const.ShipmentTypesArray;
  public showDispatcher = false;
  public shouldSelectMultipleCarrier = true;    // dynamic switch mode for nz-select
  private fetchListCarriers() {
    let url = `${Const.APIURI_CARRIERS}/list/all_for_filter`;
    this.isFetchingCarrier = true;
    this.api.GET(url).subscribe(
      (resp) => {
        Log.d("fetchListData done ", resp);
        this.listCarriers = resp.data.list_data;
        this.isFetchingCarrier = false;
      },
      (err) => {
        this.showErr(err);
        this.isFetchingCarrier = false;
      }
    );
  }

  public isFetchingClientSaleRep = false;
  private fetchListClientSales(silent: boolean = false) {
    this.isFetchingClientSaleRep = true;
    this.api.getListClientSalesRepUsersFilter().subscribe(
      resp => {
        this.listClientSales = resp?.data?.list_data ?? [];
        this.isFetchingClientSaleRep = false;
      }, err => {
        if (silent) {
          // Nếu silent thì không bắn lỗi lên màn hình.
          Log.e(err);
        } else {
          this.showErr(err);
        }
        this.isFetchingClientSaleRep = false;
      }
    );
  }
  private fetchListCarrierSales(silent: boolean = false) {
    this.isFetchingCarrierSaleRep = true;
    this.api.getListAdminUsers().subscribe(
      resp => {
        this.listCarrierSales = resp?.data?.list_data ?? [];
        this.isFetchingCarrierSaleRep = false;
      }, err => {
        if (silent) {
          // Nếu silent thì không bắn lỗi lên màn hình.
          Log.e(err);
        } else {
          this.showErr(err);
        }
        this.isFetchingCarrierSaleRep = false;
      }
    );
  }
  public getCarrierMC(carrier): string {
    return carrier?.basicInfo?.mc ?? "";
  }

  public getCarrierDOT(carrier): string {
    return carrier?.basicInfo?.dot ?? "";
  }
  getTimezoneShort() {
    const localTimeZone = DateUtil.getLocalTimeZone();
    const localTimeZoneShort = DateUtil.timezoneStandardToUsShort(localTimeZone);
    return localTimeZoneShort || '';
  }
  public startOfDay(result: Date): Date {
    return result ? startOfDay(result) : null;
  }
  public endOfDay(result: Date): Date {
    return result ? endOfDay(result) : null;
  }
  public getCarrierNameWithMCandDOT(carrier): string {
    if (carrier?.basicInfo?.mc && carrier?.basicInfo?.dot)
      return `${carrier?.basicInfo?.name} (MC:${carrier?.basicInfo?.mc}/DOT:${carrier?.basicInfo?.dot})`;
    if (carrier?.basicInfo?.mc) return `${carrier?.basicInfo?.name} (MC:${carrier?.basicInfo?.mc})`;
    if (carrier?.basicInfo?.dot) return `${carrier?.basicInfo?.name} (DOT:${carrier?.basicInfo?.dot})`;
    return carrier?.basicInfo?.name ?? "";
  }
  public getListDispatcherForDropdown() {
    let list = [...this.listDispatchers];
    return list.map(dispatcher => {
      const item = this.dispatchersHaveJobs.find(i => i._id == dispatcher.id);
      dispatcher.jobCount = item?.jobCount || 0;
      return dispatcher;
    }).filter(dispatcher => dispatcher.jobCount);
  }
  public dispatcherName(item) {
    let name: string = this.getFullName(item);
    if (item.jobCount) {
      name = `${name} (${item.jobCount})`;
    }
    return name;
  }

  getApiListDataForFilter(key: string): string|undefined {
    switch (key) {
      case 'clientId': return `${Const.APIURI_CLIENTS_FOR_FILTER}?includeSubAccount=true`;
      default: return;
    }
  }

  getVehicleType() {
    const vehicleType = this.getItemValue('vehicleType')
    if (vehicleType) {
      return { code: vehicleType}
    }
    return null;
  }

  private buildQuery(): any {
    let q = { page: this.page || 1, loaded: Date.now() };
    if (this.search) {
      q["search"] = this.search;
    }
    let condition: any = this.getFormData_JSON(true);
    if (condition.status && !Utils.isArrayNotEmpty(condition.status)) {
      delete condition.status;
    }
    if (this.needPODChecked) {
      condition.isNeedPOD = true;
    }
    if (this.isWalmartScheduledLessThanDuration) {
      condition.isWalmartScheduledLessThanDuration = true;
    }
    if (condition.sort) {
      q["sort"] = condition.sort;
      delete condition.sort;
    }
    const f = JSON.stringify(condition);
    if (f.length > 2) {
      q["filter"] = f;
    }
    q["tab"] = Utils.parseQueryStringFromUrl(this.router.url)?.tab || Const.DispatchTab.all_load;
    return q;
  }

  public getListDispatcherHaveJobAfterFilter() {
    this.listDispatcherForDropdown = this.listDispatcherForDropdown.map((dispatcher) => {
      dispatcher.jobCount = 0;
      return dispatcher;
    });
    const query = this.buildQuery();
    this.api.POST(`${Const.APIURI_JOBS}/count_job_for_dispatcher_list`, query).subscribe(
      resp => {
        this.dispatchersHaveJobs = resp.data.list_data;
        setTimeout(() => {
          this.listDispatcherForDropdown = this.getListDispatcherForDropdown();
        }, 100);
      }, err => {
        Log.e(err);
      }
    );
  }
  public isFetchingDispatcher = false;
  private fetchListDispatchers(silent: boolean = false) {
    this.isFetchingDispatcher = true;
    this.api.getListDispatcherUsersFilter().subscribe(
      resp => {
        this.listDispatchers = resp?.data?.list_data ?? [];
        this.isFetchingDispatcher = false;
        this.getListDispatcherHaveJobAfterFilter();
      }, err => {
        if (silent) {
          // Nếu silent thì không bắn lỗi lên màn hình.
          Log.e(err);
        } else {
          this.showErr(err);
        }
        this.isFetchingDispatcher = false;
      }
    );
  }

  onChange(event, key) {
    if (key == 'vehicleType') {
      this.setItemValue(key, event?.code);
    }
    this.fetchListDispatchers();
  }

  onChangeCarrier(value, key){
    if(value != 'no') {
      this.needCarrierChecked = false;
    }
    if(value == 'no') {
      this.needCarrierChecked = true;
      this.needPODChecked = false;
    }
    let lastValue = Utils.isArray(value) ? value[value.length - 1] : value;
    if (this.shouldSelectMultipleCarrier) {
      if ((lastValue === 'no' || lastValue === 'yes')) {
        this.shouldSelectMultipleCarrier = false;
        setTimeout(() => this.setItemValue('carrierId', lastValue), 5);
      }
    } else {
      if (lastValue !== 'no' && lastValue !== 'yes') {
        this.shouldSelectMultipleCarrier = true;
        setTimeout(() => this.setItemValue('carrierId', [lastValue]), 5);
      }
    }
  }

  onChangeDispatcher(value, key){
    if(value != 'no') {
      this.needDispatcherChecked = false;
    }
    if(value == 'no') {
      this.needDispatcherChecked = true;
      this.needPODChecked = false;
    }
  }

  // "Need Dispatcher" option is a shortcut of combining 2 conditions below
  // Status: Created
  // Dispatcher: has not assigned yet
  public needDispatcherChecked = false;
  onCheckboxNeedDispatcher(event) {
    this.needPODChecked = false;
    if(event == true) {
      this.setItemValue('picId', 'no')
    } else {
      this.setItemValue('picId', null)
    }
  }

  // "Need Carrier" option is a shortcut of combining 2 conditions below
  // Status: Created
  // Carrier: has not assigned yet
  public needCarrierChecked = false;
  onCheckboxNeedCarrier(event) {
    this.needPODChecked = false;
    if(event == true) {
      this.shouldSelectMultipleCarrier = false;
      setTimeout(() => this.setItemValue('carrierId', 'no'), 5);
    } else {
      this.setItemValue('carrierId', null)
    }
  }

  public needPODChecked = false;
  onCheckboxNeedPOD(event) {
    this.needDispatcherChecked = false;
    this.needCarrierChecked = false;
    this.needDriverChecked = false;
    if(event == true) {
      if(this.getFormItemValue('picId') == "no") {
        this.setItemValue('picId', null)
      }
      if(this.getFormItemValue('carrierId') == "no") {
        this.setItemValue('carrierId', null)
      }
      if(this.getFormItemValue('driverId') == "no") {
        this.setItemValue('driverId', null)
      }
    }
  }
  clearFilter() {
    for(let key of this.formInputKeys) {
      if (key === 'pickupCountry' || key === 'dropoffCountry') this.setItemValue(key, 'US');
      else this.setItemValue(key, null);
    }
    this.needDispatcherChecked = false;
    this.needCarrierChecked = false;
    this.needDriverChecked = false;
    this.needPODChecked = false;
    this.isWalmartScheduledLessThanDuration = false;
  }
  // reset form filter
  onBtnResetFilter() {
    this.clearFilter();
    this.onResetFilter();
  }

  public returnDataForm: (dataFormJson, dataCheckBox) => void = () => { }
  public onResetFilter:() => void = () => {}

  onBtnFilters() {
    this.returnDataForm(
      this.getFormData_JSON(true),
      {
        needDispatcherChecked: this.needDispatcherChecked,
        needCarrierChecked: this.needCarrierChecked,
        needPODChecked: this.needPODChecked,
        needDriverChecked:this.needDriverChecked,
        isWalmartScheduledLessThanDuration: this.isWalmartScheduledLessThanDuration
      }
    );
    this.closeDialog();
  }

  setShowDispatcher() {
    if(this.urlMyLoad == 'my_load') {
      return 'my-load'
    } else {
      return 'all-load'
    }
  }

  /**
   * Need Driver
   */
  public needDriverChecked = false;
  onCheckboxNeedDriver(event) {
    this.needPODChecked = false;
    if(event == true) {
      this.setItemValue('driverId', 'no')
    } else {
      this.setItemValue('driverId', null)
    }
  }
  public isWalmartScheduledLessThanDuration = false;
  onCheckboxWalmartFilter(event) {
    if (event) {
      this.setItemValue('clientId', ['01H394RRS3K2PFVR6K2A83D7RZ']);
    } else {
      this.setItemValue('clientId', []);
    }
  }

  // add search location

  public selectedCountryPick: string = 'US';
  public selectedCountryDrop: string = 'US';
  public country = countryListAlpha2;
  public listRegions = [];
  public listWarehouses = [];
  public listFilterWarehouses = [];
  public listFilterStates = [];
  public allStates = MasterData.getStatesUS();

  private getListWarehouse() {
    let url = `${Const.APIURI_WAREHOUSES}/list/all_for_filter`
    this.api.GET(url).subscribe(
      resp => {
        this.listWarehouses = resp?.data?.list_data ?? [];
      }, err => {
        Log.e(err);
      }
    )
  }
  private getListRegions(keyword, country) { 
    let url = `${Const.APIURI_WAREHOUSES}/get/search-regions?address=${keyword}&country=${country}`;
    this.api.GET(url).subscribe(
      resp => {
        this.listRegions = resp?.data?.list_data ?? [];
      }, err => {
        Log.e(err);
      }
    )
  }

  private timeout = null;
  onLocationSearch(key, event) {
    if (event && event.length > 1) {
      switch (key) {
        case 'pickupLocation':
          clearTimeout(this.timeout)
          this.timeout = setTimeout(() => {
            this.setListFilter(event);
            this.getListRegions(event, this.selectedCountryPick);
          }, 1000);
          break;
        case 'dropoffLocation':
          clearTimeout(this.timeout)
          this.timeout = setTimeout(() => {
            this.setListFilter(event);
            this.getListRegions(event, this.selectedCountryDrop);
          }, 1000);
          break;
        default:
          break;
      }
    }
  }

  setListFilter(key) {
    this.listFilterWarehouses = this.listWarehouses.filter(wh => wh.name.toLowerCase().includes(key.toLowerCase()));
    this.listFilterStates = this.allStates.filter(state => this.getStateDesc(state.code).toLowerCase().includes(key.toLowerCase()));
  }

  private mappingLocationFields(model: any) {
    if (model.pickupLocation?.metadata) {
      model.pickupLocation = model.pickupLocation.metadata;
    };
    if (model.pickupLocation?.state) {
      model.pickupLocation = model.pickupLocation.state;
    }
    if (model.dropoffLocation?.metadata) {
      model.dropoffLocation = model.dropoffLocation.metadata;
    };
    if (model.dropoffLocation?.state) {
      model.dropoffLocation = model.dropoffLocation.state;
    }
    this.initSelectLocationFields(model.pickupLocation, model.dropoffLocation);
  }

  private initSelectLocationFields(initPickupLocation, initDropoffLocation) {
    if (initPickupLocation?.warpId) {
      this.listFilterWarehouses = [initPickupLocation];
    } else if (initPickupLocation?.formatedAddress) {
      this.listRegions = [initPickupLocation];
    } else if (initPickupLocation) {
      this.listFilterStates = [this.allStates.find(state => state.code === initPickupLocation)];
    }
    if (initDropoffLocation?.warpId) {
      this.listFilterWarehouses = [...this.listFilterWarehouses, initDropoffLocation];
    } else if (initDropoffLocation?.formatedAddress) {
      this.listRegions = [...this.listRegions, initDropoffLocation];
    } else if (initDropoffLocation) {
      this.listFilterStates = [...this.listFilterStates, this.allStates.find(state => state.code === initDropoffLocation)];
    }
  }

}
